let timer;

export default {
  async login(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'login'
    });
  },
  async signup(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'signup'
    });
  },
  async updateEmail({ commit }, payload) {
    commit('setEmail', payload);
  },
  async forgotPassword(context, payload) {
    const url = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDjr2dsHq2OZagRTVgA55VMDzD3mq0esCs`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          requestType: 'PASSWORD_RESET',
          email: payload.email
        })
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMsg = responseData.error?.message || 'Failed to send password reset email.';
        throw new Error(errorMsg);
      }

      context.commit('setResetEmailSent', true);
      console.log("Password reset email sent successfully");
      
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      throw error;
    }
  },
  async getProfile() {
    // Firebase endpoint
    const apiBaseURL = "https://apaluma-apollo11-default-rtdb.firebaseio.com";
    try {
      const payload = {};
    
      const localId = localStorage.getItem("userId");
      const idToken = localStorage.getItem("token");
    
      if (!localId || !idToken) {
        alert("User is not authenticated. Please log in.");
        return;
      }

      const response = await fetch(
        `${apiBaseURL}/users/${localId}.json?auth=${idToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("User profile:", data);
      // Add profile details in local storage
      localStorage.setItem('firstName', data.firstName===undefined?"":data.firstName);
      localStorage.setItem('lastName', data.lastName===undefined?"":data.lastName);
      localStorage.setItem('occupation', data.occupation===undefined?"":data.occupation);
      localStorage.setItem('state', data.state===undefined?"":data.state);
      localStorage.setItem('county', data.county===undefined?"":data.county);

      
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      throw error;
    }
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let url =
      'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDjr2dsHq2OZagRTVgA55VMDzD3mq0esCs';

    if (mode === 'signup') {
      url =
        'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDjr2dsHq2OZagRTVgA55VMDzD3mq0esCs';
    }
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        returnSecureToken: true
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      let errorMsg = null;
      if (mode == 'login') {
        errorMsg = responseData.message || 'Failed to authenticate. Check your login data.'
      } else {
        errorMsg = responseData.message || 'This email is already in use. Please use a different email address.'
      }

      const error = new Error(
        errorMsg
      );
      throw error;
    }

    const expiresIn = +responseData.expiresIn * 1000;
    // const expiresIn = 5000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem('token', responseData.idToken);
    localStorage.setItem('userId', responseData.localId);
    localStorage.setItem('tokenExpiration', expirationDate);
    localStorage.setItem('email', payload.email);

    
    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    context.commit('setUser', {
      token: responseData.idToken,
      userId: responseData.localId
    });

    // Fetch user profile after login/signup
    try {
      await context.dispatch('getProfile'); // Use dispatch to invoke the getProfile action
    } catch (error) {
      console.error("Failed to fetch user profile:", error.message);
    }

    // Update user profile with name after signup
    // if (mode === 'signup' && payload.name) {
    //   await context.dispatch('updateProfile', { idToken: responseData.idToken, name: payload.name });
    // }

    // create an entry in the realtime database users thable for "signup"
    if (mode === 'signup') {
      url = `https://apaluma-apollo11-default-rtdb.firebaseio.com/users/${responseData.localId}.json?auth=${responseData.idToken}`;

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          uid: `${responseData.localId}`,
          email: payload.email
        })
      });
      console.log(response);
    }

  },

  

  // async updateProfile(context, payload) {
  //   const url = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyDjr2dsHq2OZagRTVgA55VMDzD3mq0esCs`;

  //   const response = await fetch(url, {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       idToken: payload.idToken,
  //       displayName: payload.name,
  //       returnSecureToken: false
  //     })
  //   });

  //   const responseData = await response.json();

  //   if (!response.ok) {
  //     const error = new Error(responseData.error.message || 'Failed to update profile.');
  //     throw error;
  //   }

  //   // Optionally, update the store with the new name if you want to use it later in the app
  //   context.commit('setUserProfile', { name: payload.name });
  // },
  tryLogin(context) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId
      });
    }
  },
  logout(context) {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('tokenExpiration');

    clearTimeout(timer);

    context.commit('setUser', {
      token: null,
      userId: null
    });
  },
  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  }
};
