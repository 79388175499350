<template>
  <header>
    <nav>
      <h1>
        <router-link to="/">
          {{ title }}
        </router-link>
      </h1>
      <ul>
        <li>
          <router-link to="/access-apis">
            Access APIs
          </router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/analytics">
            Analytics
          </router-link>
        </li>
        <li v-else>
          <router-link to="/auth">
            Login
          </router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link
            to="/auth"
            @click="logout"
          >
            Logout
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/auth');
    }
  }
}
</script>

<style scoped>
h1{
  font-weight: 700;
}

header {
  width: 100%;
  height: 8rem;
  grid-row: header;
  padding: 1rem;
  background-color: #4e0148;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

header a {
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
}

h1 {
  font-size: 40pt;
  color: white;
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  font-size: 20pt;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 20pt;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}
</style>
